const defaultState = {
  company: {},
  id: null,
  sections: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  statusCode: null,
  sectionsInfo: [],
};

export const connoteMessagesSuccessEvents = {
  REQUEST_UPDATE_CONNOTE_SUCCESS: ({ connote: { message } }) => message,
};

export const connoteMessagesErrorEvents = {
  REQUEST_UPDATE_CONNOTE_FAILED: ({ error: { message } }) => message,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_GET_CONNOTE_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      ...data.data[0],
    };
  },
  REQUEST_GET_CONNOTE_SECTIONS_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      sectionsInfo: data.data,
    };
  },
  REQUEST_UPDATE_CONNOTE_SUCCESS: (state, data) => {
    return {
      ...state,
      company: data.connote.data.company,
      sections: data.connote.data.sections,
      settings: data.connote.data.settings,
      isError: false,
      isSuccess: true,
      isLoading: false,
    };
  },
  REQUEST_UPDATE_CONNOTE_FAILED: (state) => {
    return {
      ...state,
      isError: true,
      isSuccess: false,
      isLoading: false,
    };
  },
  SET_CONNOTE_PROGRESS: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
};

const reducer = (state = defaultState, action = null) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
